import React from 'react';
import './styles.scss';

const Contacts = () => {
	return (
		<section className='contacts'>
			<div className='container'>
				<h1 className='contacts__title'>Контакты</h1>
				<div className='contacts__content'>
					<div className='contacts__info'>
						<ul className='contacts__info-list'>
							<li className='contacts__info-item'>Телефон: +996 220 142 170</li>
							<li className='contacts__info-item'>Почта: info@envoys.vision</li>
							<li className='contacts__info-item'>
								Адрес: г. Бишкек, ул.Раззакова 19, 10 этаж.
							</li>
						</ul>
					</div>
					<div className='contacts__map'>
						<iframe
							src='https://yandex.com/map-widget/v1/?um=constructor%3Accabb189460d92caf0e7d2c88a7bd06a5e12cb24bb2b2abe1cc70040990ae953&amp;source=constructor'
							width='100%'
							height='350'
						></iframe>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Contacts;
