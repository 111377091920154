import React, { useState, useRef, useEffect } from 'react';
import './styles.scss';
import DocImg1 from '../../../images/about/documents/doc1.png';
import DocImg2 from '../../../images/about/documents/doc2.png';
import DocImg3 from '../../../images/about/documents/doc3.png';
import DocImg4 from '../../../images/about/documents/doc4.png';
import DocImg5 from '../../../images/about/documents/doc5.png';
import DocImg6 from '../../../images/about/documents/doc6.png';
import DocImg7 from '../../../images/about/documents/doc7.png';

const AboutDocuments = () => {
	const [activeTab, setActiveTab] = useState(
		'Свидетельство Министерства юстиции'
	);
	const tabsRef = useRef(null);

	const documents = {
		'Свидетельство Министерства юстиции': {
			title: 'СВИДЕТЕЛЬСТВО О ГОСУДАРСТВЕННОЙ РЕГИСТРАЦИИ ЮРИДИЧЕСКОГО ЛИЦА',
			image: DocImg1,
			alt: 'СВИДЕТЕЛЬСТВО О ГОСУДАРСТВЕННОЙ РЕГИСТРАЦИИ ЮРИДИЧЕСКОГО ЛИЦА',
			link: 'https://drive.google.com/file/d/1vwYfiGAZVzmj26UGXFml7R1V63_5oRLT/view?usp=sharing',
		},
		'Торговая лицензия': {
			title: 'ЛИЦЕНЗИЯ НА ОРГАНИЗАЦИЮ ТОРГОВЛИ НА РЫНКЕ ЦЕННЫХ БУМАГ',
			image: DocImg2,
			alt: 'Торговая лицензия',
			link: 'https://drive.google.com/file/d/1i9lOO1ihwLbM3mdwOng4dERBgc4E6ZXR/view?usp=sharing',
		},
		'Депозитарная лицензия': {
			title:
				'ЛИЦЕНЗИЮ НА ОСУЩЕСТВЛЕНИЕ ДЕПОЗИТАРНОЙ ДЕЯТЕЛЬНОСТИ НА РЫНКЕ ЦЕННЫХ БУМАГ',
			image: DocImg3,
			alt: 'ЛИЦЕНЗИЮ НА ОСУЩЕСТВЛЕНИЕ ДЕПОЗИТАРНОЙ ДЕЯТЕЛЬНОСТИ НА РЫНКЕ ЦЕННЫХ БУМАГ',
			link: 'https://drive.google.com/file/d/1iPlqSWsE-NPmX91TAmGBMhlXxg5fxWff/view?usp=sharing',
		},
		'Лицензия торгового оператора': {
			title:
				'ЛИЦЕНЗИЯ НА ОСУЩЕСТВЛЕНИЕ ДЕЯТЕЛЬНОСТИ С ВИРТУАЛЬНЫМИ АКТИВАМИ (ОПЕРАТОР ТОРГОВЛИ)',
			image: DocImg4,
			alt: 'Лицензия торгового оператора',
			link: 'https://drive.google.com/file/d/1Gr2sMavdoFe5Kx7pDgs3vBVEDTSfh2rs/view?usp=sharing',
		},
		'Лицензия биржевого оператора': {
			title: 'ЛИЦЕНЗИЮ НА ОПЕРИРОВАНИЕ ВИРТУАЛЬНЫМИ АКТИВАМИ (ОПЕРАТОР БИРЖИ)',
			image: DocImg5,
			alt: 'Лицензия биржевого оператора',
			link: 'https://drive.google.com/file/d/11MAkTprtxowNNKV5yeRtOJWV758m9iKq/view?usp=sharing',
		},
		'Свидетельство Кыргызпатента': {
			title: 'СВИДЕТЕЛЬСТВО О ГОСУДАРСТВЕННОЙ РЕГИСТРАЦИИ ПРОГРАММЫ EVM',
			image: DocImg6,
			alt: 'Свидетельство Кыргызпатента',
			link: 'https://drive.google.com/file/d/1do2mYUWtsC5Nb4tV9hx414PBiBA5Gmp4/view?usp=sharing',
		},
		'Сертификат ТПП': {
			title: 'СЕРТИФИКАТ ТОРГОВО-ПРОМЫШЛЕННОЙ ПАЛАТЫ',
			image: DocImg7,
			alt: 'Сертификат ТПП',
			link: 'https://drive.google.com/file/d/1DfEtUXHErjcUSUKwndXrr5CcsFf6arT8/view?usp=sharing',
		},
	};

	const handleTabClick = (tab) => {
		setActiveTab(tab);
		const tabElement = tabsRef.current.querySelector(`[data-tab="${tab}"]`);
		if (tabElement) {
			tabElement.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'center',
			});
		}
	};

	useEffect(() => {
		const tabElements = tabsRef.current.querySelectorAll(
			'.about_documents__tab'
		);
		tabElements.forEach((tab) => {
			tab.addEventListener('click', () => handleTabClick(tab.dataset.tab));
		});

		return () => {
			tabElements.forEach((tab) => {
				tab.removeEventListener('click', () => handleTabClick(tab.dataset.tab));
			});
		};
	}, []);

	return (
		<div className='about_documents'>
			<h2 className='about_documents__title title'>Документы</h2>
			<div className='about_documents__container'>
				<div className='about_documents__tabs-container'>
					<div className='about_documents__tabs' ref={tabsRef}>
						{Object.keys(documents).map((tab) => (
							<button
								key={tab}
								data-tab={tab}
								className={`about_documents__tab ${
									activeTab === tab ? 'about_documents__tab--active' : ''
								}`}
							>
								{tab}
							</button>
						))}
					</div>
				</div>
				<div className='about_documents__content-wrapper'>
					<div className='about_documents__content'>
						<div className='about_documents__content-image-wrapper'>
							<img
								src={documents[activeTab].image}
								alt={documents[activeTab].alt}
								className='about_documents__content-image'
							/>
						</div>
						<div className='about_documents__content-info'>
							<h3 className='about_documents__content-title'>
								{documents[activeTab].title}
							</h3>
							<a
								target='_blank'
								href={documents[activeTab].link}
								className='about_documents__content-button'
							>
								Прочитать
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutDocuments;
