import React, { useState } from 'react';
import './styles.scss';

const RiskInfo = () => {
	const [infoList] = useState([
		{
			text: 'Риски, связанные с виртуальными активами: существуют технические риски, связанные с виртуальными активами и их базовыми технологиями, включая, но не ограничиваясь: - Недостатки, дефекты, взломы, эксплуатации, ошибки, сбои протокола или непредвиденные обстоятельства, связанные с виртуальным активом или его базовой технологией. - Невозможность отмены транзакций, что затрудняет возможность восстановления убытков в результате мошеннических или случайных транзакций. - Технологические разработки, приводящие к устареванию виртуального актива. - Задержки, из-за которых транзакции могут не быть завершены в запланированный срок. - Атаки на протокол или технологию виртуального актива, включая распределенный отказ в обслуживании, атаки типа сибил, фишинг, социальную инженерию, взлом, смурфинг, вредоносное программное обеспечение, двойные расходы, информационные кампании, разветвления и спуфинг.',
		},
		{
			text: 'Риски связи: при общении с нами электронными средствами обратите внимание, что электронная связь может быть нарушена, задержана, не иметь защиты или не достигнуть своего назначения.',
		},
		{
			text: 'Валютные риски: волатильность валютного курса может повлиять на ваши прибыли и убытки.',
		},
		{
			text: 'Политический риск: пользователи Envoys Vision Digital Exchange могут испытать финансовые убытки из-за изменений в национальном законодательстве, регулировании или макроэкономической политике, которые могут повлиять на нормальный ход сделок с криптовалютами.',
		},
		{
			text: 'Риск нарушения: пользователи Envoys Vision Digital Exchange могут понести убытки, если их криптовалютные сделки нарушают национальные законы или правила.',
		},
		{
			text: 'Риск инвестиционного дохода: рынок криптовалют уникален тем, что он никогда не закрывается, и цены на криптовалюты подвержены значительным колебаниям. Пользователи несут риск отрицательного дохода от своих сделок и могут столкнуться с финансовыми убытками.',
		},
		{
			text: 'Риск торговли: успешная сделка между пользователями требует согласия обеих сторон. Envoys Vision Digital Exchange не может гарантировать успех такой сделки.',
		},
		{
			text: 'Риск форс-мажорных событий: в случае природных катастроф, войны, забастовок, кибератак и других непредвиденных, неизбежных и невозможных ситуаций работа Envoys Vision Digital Exchange может быть нарушена, что может привести к убыткам для пользователей. Envoys Vision Digital Exchange не несет гражданской ответственности за такие убытки, вызванные форс-мажором.',
		},
		{
			text: 'Риск вывода активов: в случае банкротства, ликвидации, нарушения национальных законов и правил или в соответствии с требованиями участника проекта Envoys Vision Digital Exchange может решить вывести виртуальный актив, что может привести к финансовым убыткам для пользователей.',
		},
		{
			text: 'Риск технических проблем: хотя вероятность технической проблемы во время сделки с криптовалютой низка, мы не можем гарантировать, что она не произойдет. В случае такой ситуации это может повлиять на интересы пользователя.',
		},
		{
			text: 'Риск операционных недочетов: пользователи могут столкнуться с рисками, связанными с операционными ошибками, такими как отправка средств на неверный счет, нарушение правил операции и так далее.',
		},
		{
			text: 'Риск приостановки учетной записи: учетная запись пользователя может быть приостановлена или подвергнута процессу принудительного конфискации судебными органами в случае неплатежеспособности или подозрения в совершении преступления.',
		},
		{
			text: 'Риск третьих сторон: третьи стороны, такие как платежные поставщики, кастодианы и банковские партнеры, могут участвовать в предоставлении Услуг. Вы можете подпадать под их условия и положения, и Envoys Vision Digital Exchange не несет ответственности за любые убытки, вызванные этими третьими сторонами.',
		},
	]);

	return (
		<section className='risk'>
			<div className='container'>
				<h1 className='title'>Информация о Рисках</h1>
				<div className='risk__content'>
					<div className='risk__info'>
						<h2 className='risk__title'>
							Уважаемые клиенты Envoys Vision Digital Exchange
						</h2>
						<p className='risk__text fz_16'>
							Envoys Vision Digital Exchange напоминает вам о важности защиты
							ваших интересов в мире виртуальных активов. Рынок виртуальных
							активов известен своей высокой степенью риска. Пожалуйста,
							внимательно рассмотрите и оцените этот риск перед участием в любых
							сделках с виртуальными активами и определите свою собственную
							степень риска. <br />
							<br /> Прочтите информацию о рисках внимательно, но имейте в виду,
							что она не охватывает каждый потенциальный риск или то, как они
							связаны с вашими личными обстоятельствами. Обязательно полностью
							понимайте риски, связанные с использованием Услуг, прежде чем
							принимать решение об их использовании. <br />
							<br /> Перед использованием Услуг важно полностью понимать
							связанные с ними риски. Помните, что мы не являемся вашим
							брокером, посредником, агентом или консультантом, и не имеем
							доверительных отношений или обязательств перед вами по любым
							сделкам или другим действиям, которые вы осуществляете с
							использованием Услуг. Мы не предоставляем инвестиционных или
							консультационных советов какого-либо рода, и любую предоставленную
							нами информацию следует рассматривать не как совет. <br />
							<br /> Вы несете ответственность за определение того, является ли
							любое инвестирование, стратегия или связанная с ними сделка
							подходящей для вас, исходя из ваших инвестиционных целей,
							финансового положения и уровня риска. Кроме того, вы несете
							ответственность за любые убытки или обязательства, которые
							возникают. Мы не рекомендуем вам покупать, зарабатывать, продавать
							или удерживать какие-либо виртуальные активы. Прежде чем принимать
							решение о покупке, продаже или удержании какого-либо виртуального
							актива, проведите предварительный анализ и проконсультируйтесь с
							финансовым консультантом. Мы не несем ответственности за решения,
							которые вы принимаете относительно виртуальных активов, включая
							любые убытки, понесенные в результате этих решений.
						</p>
					</div>
					<div className='risk__info'>
						<h2 className='risk__title'>
							При торговле виртуальными активами на Envoys Vision Digital
							Exchange важно обращать внимание на следующие аспекты:
						</h2>
						<p className='risk__text'>
							1.Услуги, такие как сделки по текущей цене и OTC-торговля,
							фьючерсы, сбережения, голосование за листинг токенов и другие,
							доступны только активным пользователям Envoys Vision Digital
							Exchange (далее - "Пользователи"), успешно прошедшим KYC. <br />{' '}
							<br />
							2.Пользователи должны пройти проверку личности и заполнить всю
							личную информацию. <br /> <br /> 3.Пользователи должны нести
							ответственность за сохранение конфиденциальности своего аккаунта и
							пароля. Не делитесь своим логином и паролем с другими или не
							доверяйте им свой аккаунт. В случае нарушения этих правил все
							потери несут сами пользователи. <br /> <br /> 4.Пользователи
							Envoys Vision Digital Exchange могут столкнуться с следующими
							возможными рисками:
						</p>

						<ul className='risk__list'>
							{infoList.map((item, index) => (
								<li key={index} className='risk__list-item'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										aria-hidden='true'
										role='img'
										width='25px'
										height='25px'
										viewBox='0 0 24 24'
									>
										<path
											fill='#f48020'
											d='M20 12a8 8 0 0 1-8 8a8 8 0 0 1-8-8a8 8 0 0 1 8-8c.76 0 1.5.11 2.2.31l1.57-1.57A9.8 9.8 0 0 0 12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10M7.91 10.08L6.5 11.5L11 16L21 6l-1.41-1.42L11 13.17z'
										></path>
									</svg>
									<p>{item.text}</p>
								</li>
							))}
						</ul>
					</div>

					<div className='risk__info'>
						<h2 className='risk__title'>
							Ограниченные действия на Envoys Vision Digital Exchange:
						</h2>
						<p className='risk__text'>
							1.Запрещенные действия на Envoys Vision Digital Exchange включают
							сделки с использованием незаконно полученных средств,
							мошеннические сделки, незаконное сбор средств и случаи
							мошенничества. Использование чужих персональных данных при
							регистрации является нарушением закона и порядка, и любое
							умышленное скрытие или обман во время процедуры проверки личности
							недопустимо и подлежит юридическим последствиям. В других случаях
							Envoys Vision Digital Exchange оставляет за собой право заморозить
							или прекратить доступ к учетной записи пользователя,
							подозреваемого в совершении вышеупомянутых нарушений, или защитить
							свои цифровые активы шифрованием. <br /> <br /> 2.2.Эта информация
							о риске не охватывает все аспекты рынка виртуальных активов.
							Существует множество других рисков и факторов, которые могут
							привести к потере средств и не включены в этот список. Поэтому
							важно внимательно прочитать и полностью понять условия сделок,
							акций и сделок, а также внимательно прочитать это предупреждение о
							риске. Убедитесь, что вы понимаете риски и финансово подготовлены
							перед тем, как торговать виртуальными активами, чтобы избежать
							потенциальных убытков. <br /> <br /> 3.Пользователи несут
							ответственность за риски, связанные с торговлей на Envoys Vision
							Digital Exchange. Envoys Vision Digital Exchange никоим образом не
							гарантирует, что торговля на Envoys Vision Digital Exchange не
							приведет к убыткам для пользователей.
						</p>
					</div>
					<div className='risk__info'>
						<h2 className='risk__title'>
							Обязанности, которые должен выполнить пользователь.
						</h2>
						<p className='risk__text'>
							Как пользователь Envoys Vision Digital Exchange, вы подтверждаете
							свое согласие соблюдать национальные/региональные законы и правила
							Кыргызской Республики и других стран, в которых вы
							зарегистрированы или проживаете. В случае нарушения вами законов и
							правил или если ваши действия активируют механизм контроля риска
							Envoys Vision Digital Exchange (к которым могут относиться, но не
							ограничиваться, аномальные действия, неверные суммы торговли,
							высокие покупки и низкие продажи, крупные выводы фиата и другие
							аномальные транзакции), вы, как пользователь, соглашаетесь, что
							Envoys Vision Digital Exchange оставляет за собой право заморозить
							или прекратить работу вашего аккаунта, провести расследование, и
							вы также соглашаетесь нести юридическую ответственность за любые
							возможные убытки.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default RiskInfo;
