import React, { useState } from 'react';
import './styles.scss';

const Statement = () => {
	const [activeTab, setActiveTab] = useState('Аудированная');

	const tabs = ['Аудированная', 'Неаудированная'];

	const data = {
		Аудированная: [
			{
				id: 1,
				name: 'Аудированная финансовая отчетность за 2021г, 2022г и 2023г',
				url: '/assets/listing/audited_financial_statements_for_2021_2022_2023.pdf',
			},
		],
		Неаудированная: [
			{
				id: 1,
				name: 'Неаудированная отчетность 1кв 2024',
				url: '/assets/listing/Приложение_2_1_1_кв_2024_отчет_ОАО_ЕВДЭ.pdf',
			},
			{
				id: 2,
				name: 'Неаудированная отчетность 2кв 2024',
				url: '/assets/listing/Приложение_2_1_2_кв_2024_отчет_ОАО_ЕВДЭ.pdf',
			},
		],
	};

	const renderContent = () => {
		{
			return (
				<div className='listing__items'>
					{data[activeTab].map((item) => (
						<a
							key={item.id}
							href={item.url}
							target='_blank'
							rel='noopener noreferrer'
							className='listing__item listing__item--link'
						>
							{item.name}
						</a>
					))}
				</div>
			);
		}
	};

	return (
		<div className='statement'>
			<div className='container'>
				<h1 className='statement__title'>Финансовая отчетность</h1>
				<div className='statement__tabs'>
					{tabs.map((tab) => (
						<button
							key={tab}
							className={`statement__tab ${
								activeTab === tab ? 'statement__tab--active' : ''
							}`}
							onClick={() => setActiveTab(tab)}
						>
							{tab}
						</button>
					))}
				</div>
				<div className='statement__info'>{renderContent()}</div>
			</div>
		</div>
	);
};

export default Statement;
