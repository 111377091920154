import React, { useState } from 'react';
import './styles.scss';

const Confidentiality = () => {
	const [infoList] = useState([
		{
			text: 'Электронная идентификация: имя пользователя, учетная запись и пароль программного приложения.',
		},
		{
			text: 'Финансовые данные: реквизиты банковского счета, основной номер платежной карты (PAN), статус счета, история транзакций, сведения о сделках и/или идентификационный номер налогоплательщика.',
		},
		{
			text: 'Информация о транзакциях: данные о транзакциях, которые вы совершаете на платформе, такие как имя получателя, ваше имя, сумма и/или метка времени.',
		},
		{
			text: 'Содержание сообщения: отзывы, электронные письма, SMS-сообщения, оценки и комментарии к приложениям.',
		},
		{
			text: 'Активность приложения: просмотры веб-страниц и записи касания, история поиска, установленные приложения, активные приложения, журналы ошибок, пользовательский контент, выбранные элементы, движение курсора, позиция прокрутки, события клавиш и события касания.',
		},
		{
			text: 'Информация об устройстве: оператор, производитель, версия программного обеспечения, модель, язык системы, версия операционной системы, языковые настройки, отпечаток пальца, идентификатор сборки, версия базовой станции, страна SIM-карты, серийный номер SIM-карты, статус батареи, сеть, OAID, IMEI, GUID, MAC-адрес, идентификатор Android, SSID, рекламный идентификатор и данные гироскопа/акселерометра.',
		},
		{
			text: 'Общение: ответы на опросы, информация, предоставленная нашей службой поддержки или группой пользовательских исследований.',
		},
		{
			text: 'Информация в форме аудио: аудиозаписи, электронные, визуальные и аналогичные материалы, такие как сообщения в социальных сетях и видеозаписи.',
		},
	]);
	const [individual] = useState([
		{
			text: 'Адрес электронной почты',
		},
		{
			text: 'Номер мобильного телефона',
		},
		{
			text: 'Полное юридическое имя (включая прежние имена и имена на местном языке)',
		},
		{
			text: 'Национальность',
		},
		{
			text: 'Номер паспорта или любой идентификационный номер, выданный правительством.',
		},
		{
			text: 'Дата рождения',
		},
		{
			text: 'Документ удостоверяющий личность, такой как паспорт, водительские права или удостоверение личности, выданное правительством.',
		},
		{
			text: 'Адрес места жительства',
		},
		{
			text: 'Документ об установлении места жительства',
		},
		{
			text: 'Дополнительная личная информация или документы, которые могут быть запрошены нашей командой по мере необходимости и по нашему усмотрению.',
		},
	]);
	const [entity] = useState([
		{
			text: 'Юридическое наименование компании (включая юридическое наименование на местном языке)',
		},
		{
			text: 'Информация о создании или регистрации.',
		},
		{
			text: 'Полные юридические имена всех лиц, имеющих права на использование, управление или юридическое представительство.',
		},
		{
			text: 'Документ об удостоверении юридического существования',
		},
		{
			text: 'Описание бизнеса',
		},
		{
			text: 'Доля собственности для физических лиц (физических лиц) и компаний (корпоративных владельцев).',
		},
		{
			text: 'Контактные данные владельцев, ключевых лиц и управленческого персонала (при необходимости).',
		},
		{
			text: 'Документ, который служит для идентификации физического выгодоприобретателя корпоративного клиента, такой как паспорт, водительское удостоверение или удостоверение личности, выданное правительством.',
		},
		{
			text: 'Информация о персональных данных каждого значимого выгодоприобретателя клиентской организации (детали о персональных данных физических лиц приведены в разделе "Индивидуальный клиент" выше).',
		},
		{
			text: 'Источник богатства',
		},
		{
			text: 'Количество биткоинов или других виртуальных активов для депонирования.',
		},
	]);

	const [personalInfo] = useState([
		{
			text: 'Во время вашей онлайн-регистрации, входа или доступа к Envoys Vision Digital Exchange или использования любого сервиса Envoys Vision Digital Exchange;',
		},
		{
			text: 'При добровольном заполнении любого пользовательского опроса или отправке отзывов по электронной почте или другим доступным каналом;',
		},
		{
			text: 'Cookies через ваш браузер или программное обеспечение при использовании или просмотре наших веб-страниц или клиентов.',
		},
		{
			text: 'Другие случаи независимого сбора информации, указанные в настоящем соглашении.',
		},
	]);
	const [personalOperations] = useState([
		{
			subTitle:
				'-Соблюдение законов и нормативов. Большинство наших услуг подчинены требованиям законов и нормативов, касающихся сбора, использования и хранения ваших персональных данных определенным образом. Например, Envoys Vision Digital Exchange обязана идентифицировать и верифицировать, что клиенты, использующие наши услуги, соответствуют международным законам по предотвращению отмывания денег. Эта процедура включает в себя сбор и хранение фотографий вашего документа удостоверения личности. Если вы не соблюдаете закон, мы будем вынуждены прекратить ваш доступ к аккаунту.',
			text: 'Большинство наших услуг подчинены требованиям законов и нормативов, которые касаются сбора, использования и хранения ваших персональных данных определенным образом. Например, Envoys Vision Digital Exchange обязана идентифицировать и верифицировать, что клиенты, использующие наши услуги, соответствуют международным законам по предотвращению отмывания денег. Эта процедура включает в себя сбор и хранение фотографий вашего документа удостоверения личности. Если вы не соблюдаете закон, мы будем вынуждены прекратить ваш доступ к аккаунту.',
		},
		{
			subTitle: '-Соблюдение условий настоящего соглашения',
			text: 'Для нас всегда важно отслеживать, расследовать, предотвращать и уменьшать случаи несанкционированной или незаконной деятельности, обеспечивать выполнение соглашений с третьими сторонами и иметь механизмы для предотвращения и проверки нарушения этого соглашения. Кроме того, может потребоваться взимание платы за использование наших услуг. Мы собираем информацию о ваших взаимодействиях с нашими услугами и подробно анализируем использование вашей электронной записи. Мы можем использовать любую полученную нами персональную информацию для этих целей.',
		},
		{
			subTitle:
				'-Выявление и предотвращение мошенничества и возможных финансовых потерь',
			text: 'Мы анализируем ваши персональные данные с целью выявления, предотвращения и уменьшения возможной мошеннической деятельности и чрезмерного использования наших услуг, а также для обеспечения безопасности вашего аккаунта.',
		},
		{
			subTitle:
				'-Выявление и предотвращение мошенничества и возможных финансовых потерь',
			text: 'Мы анализируем ваши персональные данные с целью выявления, предотвращения и уменьшения возможной мошеннической деятельности и чрезмерного использования наших услуг, а также для обеспечения безопасности вашего аккаунта.',
		},
		{
			subTitle: '-Предоставление услуг',
			text: 'Нам нужен доступ к вашей персональной информации для предоставления вам наших услуг. Например, если вы планируете использовать международные услуги на нашей платформе, мы попросим вас предоставить определенные данные, такие как информация о себе, контактные данные и',
		},
		{
			subTitle: '-Предоставление коммуникационной услуги',
			text: 'Мы будем обрабатывать вашу персональную информацию с целью повышения безопасности, обеспечения контроля и верификации вашей личности и доступа к нашим услугам, борьбы со спамом, вредоносным программным обеспечением и другими угрозами безопасности, а также в соответствии с применимыми законами и стандартами безопасности. Очень важно, чтобы мы получали актуальную и точную информацию о вашем использовании наших услуг. Без обработки вашей персональной информации мы можем не иметь возможности обеспечить достаточный уровень безопасности наших услуг.',
		},
		{
			subTitle: '-Для исследований и разработок',
			text: 'Мы будем иметь доступ к вашей персональной информации, чтобы лучше понимать, как вы используете и взаимодействуете с нашими услугами. Кроме того, эта информация будет использоваться для настройки и улучшения контента и структуры наших услуг и для разработки новых услуг. Будет сложно предоставить дополнительный доступ к нашим услугам, если вы откажетесь от обработки вашей персональной информации.',
		},
		{
			subTitle: '-Улучшение вашего опыта',
			text: 'Мы будем иметь доступ к вашей персональной информации с целью предоставления вам персонализированного опыта и удовлетворения ваших потребностей. Например, вы можете разрешить нам доступ к конкретной персональной информации, которая может храниться третьими сторонами. Будет сложно гарантировать возможность продолжения использования всех или некоторых наших услуг, если вы откажетесь от обработки вашей персональной информации.',
		},
		{
			subTitle:
				'-Для упрощения процесса приобретения компаний, объединения или торговли',
			text: 'Мы можем получить доступ к любой информации, связанной с вашим аккаунтом и вашим использованием наших услуг в случае приобретения компании, объединения или другой корпоративной транзакции. Если вы не хотите, чтобы ваша персональная информация использовалась в этих целях, вы можете закрыть свой аккаунт.',
		},
		{
			subTitle: '-Участие в маркетинговых мероприятиях',
			text: 'Мы можем отправлять вам маркетинговые коммуникации, такие как электронные письма или текстовые сообщения, чтобы информировать вас о наших событиях или деятельности наших партнеров, проводить целевой маркетинг и предлагать вам акционные предложения. Наша маркетинговая стратегия будет адаптирована к вашим предпочтениям в рекламе и маркетинге в соответствии с применимым законодательством.',
		},
	]);

	const [personalUse] = useState([
		{
			subTitle: '-Филиалы',
			text: 'Ваша персональная информация, которую мы обрабатываем и собираем, может передаваться между компаниями Envoys Vision Digital Exchange в качестве естественной части наших деловых активностей и для предоставления вам наших услуг.',
		},
		{
			subTitle: '-Внешние поставщики услуг',
			text: 'Мы сотрудничаем с другими компаниями и физическими лицами, чтобы помочь нам выполнять определенные задачи, такие как анализ данных, поддержка маркетинга, обработка платежей, передача контента, оценка кредитного риска и другие. Они имеют доступ только к необходимой персональной информации и обязаны соблюдать наши соглашения и законы о защите данных.',
		},
		{
			subTitle: '-Правоохранительные органы',
			text: 'Мы можем быть обязаны законом или судом раскрывать вашу информацию правоохранительным органам и другим компетентным властям. Это может относиться к решениям суда и соблюдению законных прав или предотвращению мошенничества.',
		},
		{
			subTitle: '-Передача бизнеса',
			text: 'По мере роста нашего бизнеса мы можем покупать или продавать другие компании или услуги. Ваши пользовательские данные могут быть переданы в этих ситуациях, но будут следовать обещаниям, сделанным в наших уведомлениях о конфиденциальности (если вы не согласитесь иначе). В случае приобретения Envoys Vision Digital Exchange третьей стороной, информация пользователя может стать переданным активом.',
		},
	]);

	const [accountDel] = useState([
		{
			text: 'Вы потеряете все ваши виртуальные активы и данные, которые были в вашем аккаунте.',
		},
		{
			text: 'Вы не сможете восстановить свою персональную информацию, записи о транзакциях, бизнес-данные, историческую информацию или другие данные.',
		},
		{
			text: 'Я не смогу использовать этот аккаунт для доступа к услугам Envoys Vision Digital Exchange.',
		},
	]);

	return (
		<section className='confidentiality'>
			<div className='container'>
				<h1 className='title'>Конфиденциальность</h1>
				<div className='confidentiality__content'>
					<div className='confidentiality__info'>
						<h2 className='confidentiality__title'>Вступление</h2>
						<p className='confidentiality__text'>
							Благодарим за посещение торговой платформы "Envoys Vision Digital
							Exchange". Используя эту платформу, получая к ней доступ или
							используя ее программный интерфейс приложения или мобильное
							приложение, вы соглашаетесь с политикой конфиденциальности и
							практиками, регулирующими использование ваших персональных данных
							("Политика конфиденциальности"). Пожалуйста, внимательно
							ознакомьтесь с этой Политикой конфиденциальности. В этом документе
							объясняется, как "Envoys Vision Digital Exchange" обрабатывает
							ваши персональные данные (определены ниже), предоставляя вам
							доступ и услуги через нашу платформу для торговли виртуальными
							активами с использованием программного обеспечения, программного
							интерфейса приложения (API), технологий и продуктов.
						</p>
					</div>
					<div className='confidentiality__info'>
						<h2 className='confidentiality__title'>
							Информация, которую мы получаем.
						</h2>
						<p className='confidentiality__text'>
							Envoys Vision Digital Exchange собирает, обрабатывает и хранит
							персональную информацию, которую вы предоставляете при
							использовании Сервиса или после предоставления вашего согласия.
							Эта персональная информация может включать в себя:
						</p>
						<ul className='confidentiality__list'>
							{infoList.map((item, index) => (
								<li key={index} className='confidentiality__list-item'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										aria-hidden='true'
										role='img'
										width='25px'
										height='25px'
										viewBox='0 0 24 24'
									>
										<path
											fill='#f48020'
											d='M20 12a8 8 0 0 1-8 8a8 8 0 0 1-8-8a8 8 0 0 1 8-8c.76 0 1.5.11 2.2.31l1.57-1.57A9.8 9.8 0 0 0 12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10M7.91 10.08L6.5 11.5L11 16L21 6l-1.41-1.42L11 13.17z'
										></path>
									</svg>
									<p>{item.text}</p>
								</li>
							))}
						</ul>
						<p className='confidentiality__text'>
							Мы можем получать информацию, которую вы предоставляете в процессе
							регистрации на Envoys Vision Digital Exchange, которая может быть
							полной, неполной или приостановленной. Предоставляя услуги жителям
							определенных юрисдикций, мы собираем, храним и обрабатываем ваши
							персональные данные в соответствии с требованиями местного
							законодательства о защите персональных данных. <br />
							<br /> Кроме того, для соблюдения соответствующих законов и
							нормативов, регулирующих борьбу с отмыванием денег, мы можем
							получать следующие персональные данные:
						</p>
						<span className='confidentiality__text-gray'>Физические лица:</span>
						<ul className='confidentiality__list'>
							{individual.map((item, index) => (
								<li key={index} className='confidentiality__list-item'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										aria-hidden='true'
										role='img'
										width='25px'
										height='25px'
										viewBox='0 0 24 24'
									>
										<path
											fill='#f48020'
											d='M20 12a8 8 0 0 1-8 8a8 8 0 0 1-8-8a8 8 0 0 1 8-8c.76 0 1.5.11 2.2.31l1.57-1.57A9.8 9.8 0 0 0 12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10M7.91 10.08L6.5 11.5L11 16L21 6l-1.41-1.42L11 13.17z'
										></path>
									</svg>
									<p>{item.text}</p>
								</li>
							))}
						</ul>
						<span className='confidentiality__text-gray'>
							Корпоративные клиенты:
						</span>
						<ul className='confidentiality__list'>
							{entity.map((item, index) => (
								<li key={index} className='confidentiality__list-item'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										aria-hidden='true'
										role='img'
										width='25px'
										height='25px'
										viewBox='0 0 24 24'
									>
										<path
											fill='#f48020'
											d='M20 12a8 8 0 0 1-8 8a8 8 0 0 1-8-8a8 8 0 0 1 8-8c.76 0 1.5.11 2.2.31l1.57-1.57A9.8 9.8 0 0 0 12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10M7.91 10.08L6.5 11.5L11 16L21 6l-1.41-1.42L11 13.17z'
										></path>
									</svg>
									<p>{item.text}</p>
								</li>
							))}
						</ul>
					</div>
					<div className='confidentiality__info'>
						<h2 className='confidentiality__title'>
							Права, связанные с доступом, исправлением
						</h2>
						<p className='confidentiality__text'>
							В соответствии с применимым законодательством, как описано ниже, у
							вас есть ряд прав, касающихся вашей конфиденциальности и защиты
							ваших персональных данных. У вас есть право запрашивать доступ,
							исправление и удаление ваших персональных данных, а также
							запрашивать перенос данных. Вы также имеете право возражать против
							обработки ваших персональных данных или запрашивать ограничение
							обработки ваших персональных данных в определенных случаях. Кроме
							того, если вы дали согласие на обработку ваших персональных данных
							для определенной цели, вы можете отозвать свое согласие в любое
							время. Обратите внимание, что эти права могут быть ограничены в
							определенных ситуациях, например, когда мы обязаны обрабатывать
							ваши персональные данные в соответствии с применимыми законами или
							антиотмывочными практиками.
						</p>
						<p className='confidentiality__text confidentiality__text-small'>
							-Право на получение информации: У вас есть право получить
							подтверждение того, что ваши персональные данные обрабатываются, а
							также копию этих данных, а также конкретную информацию об их
							обработке. <br /> <br /> -Право на исправление: Вы можете
							запросить исправление и дополнение любых неточностей в ваших
							персональных данных. Кроме того, вы можете изменить свою
							персональную информацию в своем аккаунте в любое время. <br />
							<br /> -Право на удаление: В определенных обстоятельствах вы
							можете запросить удаление ваших персональных данных. <br /> <br />{' '}
							-Право на возражение: Вы можете возражать против обработки ваших
							персональных данных в случаях, связанных с вашей конкретной
							ситуацией. Например, у вас есть право возражать, если мы
							используем законный интерес или обрабатываем ваши персональные
							данные в маркетинговых целях. <br /> <br /> -Право на ограничение
							обработки: В определенных обстоятельствах у вас есть возможность
							временно ограничить обработку ваших персональных данных нами, если
							для этого имеются важные причины. Мы можем продолжать обработку
							ваших персональных данных при необходимости для защиты правовых
							требований или по другим причинам, предусмотренным применимым
							законодательством. <br /> <br /> -Право на передачу: В некоторых
							ситуациях вы можете запросить передачу ваших персональных данных,
							предоставленных вами, в структурированном, общепринятом и
							машинночитаемом формате или, при возможности, передачи их
							непосредственно другому контроллеру данных от вашего имени. <br />{' '}
							<br /> -Право на отзыв согласия: Для обработки, требующей вашего
							согласия, у вас есть право отозвать это согласие в любое время.
							Отзыв согласия не влияет на законность обработки, проведенной на
							основе вашего согласия до его отзыва.
						</p>
						<p className='confidentiality__text'>
							Ваше осуществление вышеуказанных прав может повлиять на форму и
							характер предоставляемых вам услуг и, в некоторых случаях, может
							привести к невозможности продолжения предоставления услуг и,
							возможно, к прекращению вашего договора с нами. <br />
							<br /> Мы можем взимать разумную плату за обработку вашего запроса
							на доступ к персональным данным. Если мы решим взимать плату, мы
							предоставим вам письменную оценку этой платы до ее взимания.
							Пожалуйста, обратите внимание, что мы можем отказать в ответе на
							ваш запрос или не рассматривать его, если вы откажетесь оплатить
							плату.
						</p>
					</div>
					<div className='confidentiality__info'>
						<h2 className='confidentiality__title'>
							Сбор персональной информации об отдельных лицах.
						</h2>
						<p className='confidentiality__text'>
							Вы лично предоставляете нам большую часть персональной информации,
							которую мы собираем. Мы собираем и обрабатываем вашу информацию в
							следующих случаях:
						</p>
						<ul className='confidentiality__list'>
							{personalInfo.map((item, index) => (
								<li key={index} className='confidentiality__list-item'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										aria-hidden='true'
										role='img'
										width='25px'
										height='25px'
										viewBox='0 0 24 24'
									>
										<path
											fill='#f48020'
											d='M20 12a8 8 0 0 1-8 8a8 8 0 0 1-8-8a8 8 0 0 1 8-8c.76 0 1.5.11 2.2.31l1.57-1.57A9.8 9.8 0 0 0 12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10M7.91 10.08L6.5 11.5L11 16L21 6l-1.41-1.42L11 13.17z'
										></path>
									</svg>
									<p>{item.text}</p>
								</li>
							))}
						</ul>
						<p className='confidentiality__text'>
							Мы также можем получать информацию из общедоступных источников или
							от третьих сторон, таких как наши партнеры по рекламе и
							маркетинговым исследованиям. Это может включать в себя обновление
							или дополнение уже собранной о вас информации.
						</p>
					</div>
					<div className='confidentiality__info'>
						<h2 className='confidentiality__title'>
							Операции с персональными данными.
						</h2>
						<p className='confidentiality__text'>
							Мы можем использовать информацию о вас в следующих контекстах:
						</p>
						<ul className='confidentiality__list'>
							{personalOperations.map((item, index) => (
								<li key={index} className='confidentiality__list-item'>
									<div>
										<p className='confidentiality__text confidentiality__text fz_16'>
											{item.subTitle}
										</p>
										<p className='confidentiality__text confidentiality__text-gray margin_unset fz_14'>
											{item.text}
										</p>
									</div>
								</li>
							))}
						</ul>
						<p className='confidentiality__text'>
							Мы можем использовать вашу персональную информацию для любых
							целей, на которые вы дали согласие:
						</p>
						<ul className='confidentiality__list'>
							{personalUse.map((item, index) => (
								<li key={index} className='confidentiality__list-item'>
									<div>
										<p className='confidentiality__text confidentiality__text fz_16'>
											{item.subTitle}
										</p>
										<p className='confidentiality__text confidentiality__text-gray margin_unset fz_14'>
											{item.text}
										</p>
									</div>
								</li>
							))}
						</ul>
					</div>
					<div className='confidentiality__important'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							aria-hidden='true'
							role='img'
							width='30px'
							height='30px'
							viewBox='0 0 16 16'
						>
							<g fill='#f48020'>
								<path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z'></path>
								<path d='M7.002 11a1 1 0 1 1 2 0a1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z'></path>
							</g>
						</svg>
						<p>
							Мы не передаем вашу персональную информацию третьим лицам без
							вашего согласия, за исключением случаев, когда это третье лицо и
							Envoys Vision Digital Exchange предоставляют вам услугу совместно
							либо в случаях предусмотренных законодательством. После завершения
							обслуживания доступ к вашей информации будет запрещен. Кроме того,
							Envoys Vision Digital Exchange блокирует сбор, редактирование,
							продажу и распространение вашей персональной информации третьим
							лицам. Если пользователь нарушает эти условия, Envoys Vision
							Digital Exchange может прекратить договор об обслуживании.
						</p>
					</div>
					<div className='confidentiality__info'>
						<h2 className='confidentiality__title'>Хранение данных</h2>
						<p className='confidentiality__text'>
							Ваша информация будет храниться на серверах Envoys Vision Digital
							Exchange и может быть передана за пределы страны, где она была
							собрана, и, следовательно, использоваться в различных местах.
							Предоставляя свои данные, вы соглашаетесь с этим процессом.
							Дополнительную информацию о передаче данных за пределы ЕС можно
							найти в разделе "Дополнительные положения для пользователей из
							ЕС".
						</p>
					</div>
					<div className='confidentiality__info'>
						<h2 className='confidentiality__title'>
							Передача информации за границу.
						</h2>
						<p className='confidentiality__text'>
							Для облегчения наших глобальных операций Envoys Vision Digital
							Exchange может передавать ваши персональные данные за пределы
							Кыргызской Республики. Эта передача иногда называется "передачей в
							третью страну". Мы также делимся вашими данными с нашими
							партнерами и поставщиками услуг по всему миру и применяем меры
							безопасности для обеспечения соответствия законам о защите данных.
						</p>
					</div>
					<div className='confidentiality__info'>
						<h2 className='confidentiality__title'>Cookies</h2>
						<p className='confidentiality__text'>
							Мы используем файлы cookie для отслеживания производительности и
							эффективности рекламы на платформе, которые сохраняются на вашем
							компьютере при использовании платформы. <br />
							<br /> Файлы cookie записывают привычки и предпочтения
							пользователей на платформе, собирают анонимную статистику без
							личных данных и помогают распознавать веб-браузеры и запоминать
							информацию, но не извлекают личную информацию с жесткого диска или
							адреса электронной почты. <br />
							<br />
							Отключение файлов cookie. Большинство браузеров уже настроены на
							принятие файлов cookie, но вы можете установить свой браузер на
							отклонение файлов cookie или уведомление о их установке. Важно
							знать, что это может ограничить доступ к некоторым функциям
							платформы, использующим файлы cookie. Если вы откажетесь от файлов
							cookie, вы можете не иметь возможность войти или использовать
							услуги и функции платформы, использующие эти файлы. Эта информация
							относится ко всем файлам cookie, установленным Envoys Vision
							Digital Exchange
						</p>
					</div>
					<div className='confidentiality__info'>
						<h2 className='confidentiality__title'>
							Информационная безопасность
						</h2>
						<p className='confidentiality__text'>
							Ваш аккаунт в безопасности. Храните свою информацию и пароль в
							безопасности. Мы обеспечиваем защиту данных через резервное
							копирование и шифрование, но помните, что защита информации не
							идеальна. Берегите свою личную информацию и сообщайте о утечке,
							если она произойдет. <br />
							<br /> Мы будем хранить ваши персональные данные в соответствии с
							законом. Если они больше не нужны для заявленных целей и не
							требуются другими законными интересами, мы можем удалить или
							анонимизировать их. Если вы прекращаете использование наших услуг,
							мы можем продолжать хранить и использовать ваши данные в
							соответствии с законом.
						</p>
					</div>
					<div className='confidentiality__info'>
						<h2 className='confidentiality__title'>Удаление аккаунта</h2>
						<p className='confidentiality__text'>
							Вы можете удалить свой аккаунт в Envoys Vision Digital Exchange в
							любое время. При этом вас ожидают следующие последствия, включая,
							но не ограничиваясь:
						</p>
						<ul className='confidentiality__list'>
							{accountDel.map((item, index) => (
								<li key={index} className='confidentiality__list-item'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										aria-hidden='true'
										role='img'
										width='25px'
										height='25px'
										viewBox='0 0 24 24'
									>
										<path
											fill='#f48020'
											d='M20 12a8 8 0 0 1-8 8a8 8 0 0 1-8-8a8 8 0 0 1 8-8c.76 0 1.5.11 2.2.31l1.57-1.57A9.8 9.8 0 0 0 12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10M7.91 10.08L6.5 11.5L11 16L21 6l-1.41-1.42L11 13.17z'
										></path>
									</svg>
									<p>{item.text}</p>
								</li>
							))}
						</ul>
						<p className='confidentiality__text fz_14'>
							После удаления аккаунта восстановление становится невозможным. Мы
							напомним вам об этих рисках перед удалением вашего аккаунта, чтобы
							защитить ваши права. <br />
							<br />
							Envoys Vision Digital Exchange напоминает вам о важности защиты
							ваших интересов в мире криптовалют. Рынок криптовалют известен
							своей высокой степенью риска. Пожалуйста, тщательно оцените этот
							риск перед участием в любых операциях с криптовалютами и
							определите свою собственную степень риска.
						</p>
					</div>
					<div className='confidentiality__info'>
						<h2 className='confidentiality__title'>Уведомления и изменения</h2>
						<p className='confidentiality__text'>
							Если у вас есть вопросы о конфиденциальности в Envoys Vision
							Digital Exchange., не стесняйтесь связаться с нами. Мы всегда
							готовы предоставить ответы как можно скорее. <br /> <br /> Наш
							бизнес постоянно меняется, и наша политика конфиденциальности
							может изменяться вместе с ним. Проверяйте наши веб-сайты часто,
							чтобы быть в курсе последних изменений. Наша текущая политика
							конфиденциальности в настоящее время распространяется на всю
							информацию, которую мы собираем о вас и вашем аккаунте. <br />
							<br /> Обратите внимание, что наша политика конфиденциальности не
							распространяется на политики конфиденциальности или правила
							третьих сторон, таких как Trading View или другие, которые могут
							быть не связаны с нашей политикой конфиденциальности.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Confidentiality;
