import React from 'react';
import './styles.scss';
import AboutImg1 from '../../images/about/about1.png';
import AboutImg2 from '../../images/about/about2.png';
import AboutImg3 from '../../images/about/about3.png';
import AboutDocuments from './about_documents/AboutDocuments';

const About = () => {
	return (
		<section className='about'>
			<div className='container'>
				<div className='about__df'>
					<div className='about__info'>
						<h1 className='about__title title'>
							КОМПАНИЯ ОАО{' '}
							<span className='about__title-orange'>ENVOYS VISION</span>{' '}
							ЦИФРОВАЯ БИРЖА
						</h1>
						<p className='about__text'>
							В Envoys Vision мы верим в то, что каждый человек имеет право на
							свободу зарабатывать, хранить, тратить и переводить свои деньги,
							независимо от своего происхождения и личных характеристик .
						</p>
					</div>
					<div className='about__img-block'>
						<img src={AboutImg1} alt='about' className='about__img' />
					</div>
				</div>
				<AboutDocuments />
				<div className='about__df'>
					<div className='about__info'>
						<h1 className='about__title title'>ИСТОРИЯ ENVOYS VISION</h1>
						<p className='about__text about__text-small'>
							Открытое акционерное общество Цифровая биржа Envoys Vision начало
							свою деятельность в 2021 году и сейчас предоставляет современные,
							технологические решения на финансовых рынках в организации
							торговли, будучи универсальной биржей. Деятельность компании
							направлена на торговлю акциями, облигациями, деривативами,
							драгоценными металлами, валютами, криптоактивами, инструментами
							денежного рынка и товарами. <br />
							<br /> Общество является самостоятельным юридическим лицом,
							действует на основе открытости, качества, постоянного развития,
							технологичности, законности и Устава Общества. Компания
							осуществляет свою деятельность в строгом соответствии с
							требованиями Законодательства и получила для этого соответствующие
							сертификаты и лицензии. <br />
							<br /> Сотрудники были набраны Компанией из разных секторов рынка
							и являются квалифицированными специалистами с достаточным опытом
							работы на рынке ценных бумаг и пониманием механики взаимодействия
							между участниками биржи. Руководство Компании имеет большой опыт
							работы на руководящих должностях на фондовой бирже, в банке и в
							ИТ-компании.
						</p>
						<a
							href='https://mail.google.com/mail/u/0/?fs=1&to=office@envoys.vision&tf=cm'
							target='_blank'
							rel='noreferrer'
							className='about__link'
						>
							Свяжитесь с нами
						</a>
					</div>
					<div className='about__img-block'>
						<img src={AboutImg2} alt='about' className='about__img' />
					</div>
				</div>
				<div className='about__df about__df-reverse'>
					<div className='about__img-block'>
						<img src={AboutImg3} alt='about' className='about__img' />
					</div>
					<div className='about__info'>
						<h1 className='about__title title'>
							ЦИФРОВАЯ БИРЖА ENVOYS VISION ЭТО
						</h1>
						<p className='about__text about__text-small'>
							Цифровая биржа Envoys Vision - это платформа для трейдеров и
							инвесторов, которая включает в себя фондовые, валютные, сырьевые и
							криптовалютные рынки. Платформа была разработана учредителями
							компании самостоятельно, в результате чего Государственная служба
							по интеллектуальной собственности и инновациям (Кыргызпатент)
							приняла решение об официальной регистрации компьютерной программы
							и получила Свидетельство Кыргызпатента. <br />
							<br /> Биржа предлагает различные виды услуг, а также
							предоставляет доступ к более чем 50 ведущим криптоактивам и другим
							финансовым инструментам.
							<br />
							<br /> Мы ставим надежность, безопасность и прозрачность на первое
							место и стремимся обеспечить наилучшие условия для торговли и
							инвестирования. Мы предлагаем быстрый доступ к рынку и постоянно
							совершенствуем и расширяем наши услуги и инструменты, чтобы помочь
							клиентам достичь своих финансовых целей.
						</p>
						<a
							href='https://mail.google.com/mail/u/0/?fs=1&to=office@envoys.vision&tf=cm'
							target='_blank'
							className='about__link'
						>
							Свяжитесь с нами
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default About;
