import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
	const [openDropdown, setOpenDropdown] = useState(null);
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);
	const dropdownRef = useRef(null);
	const location = useLocation();

	const isHomePage = location.pathname === '/';

	const menuItems = [
		{
			title: 'Темы',
			dropdown: [{ text: 'Новости', href: '/exchange-news' }],
		},
		{
			title: 'О Бирже',
			dropdown: [
				{ text: 'О представителях', href: '/about' },
				{ text: 'Документы', href: '/documents' },
				{ text: 'Листинг', href: '/listing' },
				{ text: 'Финансовая отчетность', href: '/statement' },
			],
		},
	];

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			setIsScrolled(scrollPosition > 240);
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	const headerClass = `header ${isHomePage ? 'header--home' : ''} ${
		isScrolled ? 'header--scrolled' : ''
	}`;

	const toggleDropdown = (index) => {
		setOpenDropdown(openDropdown === index ? null : index);
	};

	const toggleMobileMenu = () => {
		setIsMobileMenuOpen(!isMobileMenuOpen);
		document.body.style.overflow = !isMobileMenuOpen ? 'hidden' : '';
	};

	const handleLinkClick = () => {
		setIsMobileMenuOpen(false);
		setOpenDropdown(null);
		document.body.style.overflow = '';
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setOpenDropdown(null);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<header className={headerClass}>
			<div className='container'>
				<div className='header__content'>
					<div className='header__logo'>
						<Link to='/'>
							<img
								src='/white-logo.svg'
								alt='logo'
								className='header__logo-image'
							/>
						</Link>
					</div>
					<button
						className={`header__mobile-toggle ${
							isMobileMenuOpen ? 'header__mobile-toggle--open' : ''
						}`}
						onClick={toggleMobileMenu}
					>
						<span className='header__mobile-toggle-icon'></span>
					</button>
					<nav
						className={`header__nav ${
							isMobileMenuOpen ? 'header__nav--open' : ''
						}`}
					>
						<ul className='header__nav-list'>
							{menuItems.map((item, index) => (
								// <li key={index} className='header__nav-item'>
								// 	<Link
								// 		onClick={handleLinkClick}
								// 		className='header__nav-link'
								// 		to={item.href}
								// 	>
								// 		{item.text}
								// 	</Link>
								// </li>

								<li key={index} className='header__nav-item' ref={dropdownRef}>
									<button
										className='header__nav-link'
										onClick={() => toggleDropdown(index)}
									>
										{item.title}
										<svg
											data-v-c3ad5561=''
											data-v-1d917fb3=''
											xmlns='http://www.w3.org/2000/svg'
											aria-hidden='true'
											role='img'
											className={`icon ml-1 mt-1 ${
												openDropdown === index ? 'icon--rotated' : ''
											}`}
											width='18px'
											height='18px'
											viewBox='0 0 24 24'
										>
											<path
												fill='currentColor'
												d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6l-6-6z'
											></path>
										</svg>
									</button>

									<ul
										className={`header__dropdown-menu ${
											openDropdown === index
												? 'header__dropdown-menu--open'
												: ''
										}`}
									>
										{item.dropdown.map((subItem, subIndex) => (
											<li key={subIndex} className='header__dropdown-item'>
												<Link
													to={subItem.href}
													className='header__dropdown-link'
													onClick={handleLinkClick}
												>
													{subItem.text}
												</Link>
											</li>
										))}
									</ul>
								</li>
							))}
						</ul>
					</nav>
				</div>
			</div>
		</header>
	);
};

export default Header;
