import React, { useState } from 'react';
import './styles.scss';

const Onboard = () => {
	const [onboardLinks] = useState([
		{
			text: 'Список документов для онбординга',
			href: 'https://docs.google.com/document/d/1Bp0y9cXRnj4ejxK_iV7dXf7n1kkF-3BH/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
		},
		{
			text: 'Карточка образцов подписей',
			href: 'https://docs.google.com/document/d/1MNM4E7pGKnTt3g-ci5_LmCibg5AGVWMI/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
		},
		{
			text: 'Анкета партнера для юридических лиц',
			href: 'https://docs.google.com/document/d/1hWwtTL27zft6vZOuo0dknFb2_on2z6c5/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
		},
		{
			text: 'Анкета клиента для юридических лиц',
			href: 'https://docs.google.com/document/d/1gaZh_Z5zoQVcgrhYf2RY4946qN5uWHZ1/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
		},
		{
			text: 'Анкета партнера для юридических лиц на английском',
			href: 'https://docs.google.com/document/d/15ZUna33xnBCU-SkZvhX1Ub2ecxAYBCGp/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
		},
		{
			text: 'Анкета для клиентов физических лиц',
			href: 'https://docs.google.com/document/d/1gaZh_Z5zoQVcgrhYf2RY4946qN5uWHZ1/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
		},
	]);

	return (
		<div className='onboard'>
			<h3 className='onboard__title'>Онбординг</h3>
			<ul className='onboard__list'>
				{onboardLinks.map((item, index) => (
					<li key={index} className='onboard__item'>
						<a target='_blank' href={item.href}>
							{item.text}
						</a>
					</li>
				))}
			</ul>
		</div>
	);
};

export default Onboard;
