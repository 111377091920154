import React, { useState } from 'react';
import PartnerImg1 from '../../images/partners/partner1.png';
import PartnerImg2 from '../../images/partners/partner2.png';
import PartnerImg3 from '../../images/partners/partner3.png';
import PartnerImg4 from '../../images/partners/partner4.png';
import PartnerImg5 from '../../images/partners/partner5.png';
import PartnerImg6 from '../../images/partners/partner6.png';
import PartnerImg7 from '../../images/partners/partner7.png';
import PartnerImg8 from '../../images/partners/sakbolkamsyzdan.png';
import './styles.scss';

const Partners = () => {
	const [partners] = useState([
		{
			img: PartnerImg1,
			id: 'partner1',
			href: 'https://www.kraken.com/',
		},
		{
			img: PartnerImg2,
			id: 'partner2',
			href: 'https://rpg.kg/',
		},
		{
			img: PartnerImg3,
			id: 'partner3',
			href: 'https://onealpha.io/',
		},
		{
			img: PartnerImg4,
			id: 'partner4',
			href: 'https://www.cbk.kg/',
		},
		{
			img: PartnerImg5,
			id: 'partner5',
			href: 'https://omegacapital.kg/',
		},
		{
			img: PartnerImg6,
			id: 'partner6',
			href: 'https://kcsd.kz/',
		},
		{
			img: PartnerImg7,
			id: 'partner7',
			href: 'https://sumsub.com/',
		},
		{
			img: PartnerImg8,
			id: 'partner8',
			href: 'http://sakbol.kg/',
		},
	]);

	return (
		<>
			<section className='partners'>
				<div className='container'>
					<h1 className='partners__title title'>Партнеры</h1>
					<div className='partners__content'>
						{partners.map((partner, index) => (
							<div className='partners__col' key={index}>
								<a
									href={partner.href}
									target='_blank'
									className='partners__link'
								>
									<div
										className={`partners__img-block partners__img-block--${partner.id}`}
									>
										<img
											src={partner.img}
											className='partners__img'
											alt='partner'
										/>
									</div>
								</a>
							</div>
						))}
					</div>
				</div>
			</section>
		</>
	);
};

export default Partners;
