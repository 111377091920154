import React from 'react';
import Video from '../../images/hero/cover.mp4';
import './styles.scss';

const Hero = () => {
	return (
		<section className='hero'>
			<div className='hero__video-block'>
				<video
					autoPlay={true}
					loop={true}
					playsInline={true}
					muted={true}
					className='hero__video'
				>
					<source src={Video} type='video/mp4' />
				</video>
			</div>
			<div className='hero__info'>
				<h1 className='hero__title'>
					Экосистема цифрового обмена Envoys Vision
				</h1>
				<p className='hero__text'>
					Envoys Vision - это доступность к финансовым рынкам, безопасность
					инвестирования в стартапы, высокая доходность для поставщиков
					ликвидности!
				</p>
				<div className='hero__presentation_block'>
					<a
						href='https://drive.google.com/file/d/1KKGtSQtYqfJ0Tb1zfoWlD0hf5NJlN73q/view?usp=sharing'
						className='hero__presentation'
						target='_blank'
					>
						О компании
					</a>
				</div>
			</div>
		</section>
	);
};

export default Hero;
