import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';

const News = () => {
	const newsData = {
		exchangeNews: [
			{
				id: 1,
				date: '10.05.2024',
				title:
					'ОАО «ЕНВОЙС ВИЖИОН ДИДЖИТАЛ ЭКСЧЕЙНДЖ» Сообщает, что 29 апреля 2024 года состоялось годовое общее собрание акционеров',
				href: '/assets/news/Газета ПДФ 10 мая 2024, 10  стр.pdf#page=10',
			},
			{
				id: 2,
				date: '14.11.2023',
				title:
					'Внеочередное общее собрание акционеров Открытого акционерного общества «ЕНВОЙС ВИЖИОН ДИДЖИТАЛ ЭКСЧЕЙНДЖ»',
				href: '/assets/news/эркин тоо газета.pdf#page=14',
			},
			{
				id: 3,
				date: '20.10.2023',
				title:
					'Внеочередное общее собрание акционеров Открытого акционерного общества «ЕНВОЙС ВИЖИОН ДИДЖИТАЛ ЭКСЧЕЙНДЖ»',
				href: '/assets/news/Газета эркин тоо.pdf#page=10',
			},
		],
		companyNews: [
			{
				id: 1,
				date: '30.07.2024',
				title: 'Отчет ОАО «Роял» за 2 квартал 2024 года',
				href: '/assets/news/royal.pdf',
			},
			{
				id: 2,
				date: '30.10.2024',
				title: 'Отчет ОАО «Роял» за 3 квартал 2024 года',
				href: '/assets/news/royal_2.pdf',
			},
		],
	};

	return (
		<div className='news-block'>
			<div className='container'>
				<h2 className='news-block__title title'>Новости</h2>
				<div className='news-block__columns'>
					<div className='news-block__column'>
						<h3 className='news-block__subtitle'>Новости биржи:</h3>
						<ul className='news-block__list'>
							{newsData.exchangeNews.map((news) => (
								<li key={news.id} className='news-block__item'>
									<span className='news-block__date'>{news.date}</span>
									<a target='_blank' rel='noreferrer' href={news.href}>
										{news.title}
									</a>
								</li>
							))}
						</ul>
						<Link to='exchange-news' className='news-block__link'>
							Все новости →
						</Link>
					</div>

					<div className='news-block__column'>
						<h3 className='news-block__subtitle'>Новости компаний:</h3>
						<ul className='news-block__list'>
							{newsData.companyNews.map((news) => (
								<li key={news.id} className='news-block__item'>
									<span className='news-block__date'>{news.date}</span>
									<a target='_blank' href={news.href}>
										{news.title}
									</a>
								</li>
							))}
						</ul>
						<Link to='company-news' className='news-block__link'>
							Все новости →
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default News;
