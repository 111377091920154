import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const AboutUs = () => {
	return (
		<>
			<section className='about_us'>
				<div className='container'>
					<div className='about_us__content'>
						<h1 className='about_us__title title'>
							Что такое{' '}
							<span className='about_us__title about_us__title_yellow'>
								Envoys Vision
							</span>{' '}
							Digital exchange
						</h1>
						<div className='about_us__block'>
							<p className='about_us__desc'>
								Envoys Vision Digital Exchange - это платформа для трейдеров и
								инвесторов, которая предлагает широкий выбор криптоактивов и
								финансовых инструментов, включая уникальные инвестиционные
								продукты в других секторах
								<br />
								<br />
								Мы ставим на первое место надежность, безопасность и
								прозрачность и стремимся предоставить лучшие условия для
								торговли и инвестирования. Мы предлагаем быстрый доступ к рынку
								и постоянно улучшаем и расширяем наши услуги и инструменты,
								чтобы помочь клиентам достичь их финансовых целей.
							</p>
							<div className='about_us__link-block'>
								<Link to='/about' className='about_us__link'>
									О нас
									<svg
										className='about_us__link-icon'
										width='10'
										height='10'
										viewBox='0 0 10 10'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M8.00208 3.0605L1.54683 9.51575L0.486328 8.45525L6.94083 2H1.25208V0.5H9.50208V8.75H8.00208V3.0605Z'
											fill='current'
										></path>
									</svg>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default AboutUs;
